/* Import Livory Font */
@import url("https://use.typekit.net/nyf6wcq.css");

/* High Level Page CSS */

.App{
  max-width: 100%;
  height: 100%;
  margin: 0;
  padding: 0px;
}

/* Header Styles */

.Header_Links{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  
}
.Header_Nav{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding: 15px 25px 20px 25px;
  margin: 0px auto 20px;
  font-family: 'Helvetica Neue', 'Times New Roman', Times, sans-serif;
  text-decoration: none;
  font-size: 18px;
  font-weight: 400;
}
.Header_Links a{
  color: #3c3c3c;
  text-decoration: none;
  display: block;
  padding: 5px 10px 0px 5px;
  transition: border-bottom 200ms ease;
  box-shadow: none;
}
.Header_Links a:visited{
  color: #3c3c3c;
  text-decoration: none;
}
.Header_Links a:hover{
  text-decoration:none;
  color: #658E9C;
}
.Header_Wrapper{
  background: transparent;
}

.SiteLogo{
  font-family: "Livory", serif, 'Times New Roman', Times, sans-serif;
  font-size: 30px;
  font-weight: 400;
  padding-left:5px;
 
  
}
.SiteLogo a{
  color: #3c3c3c;
}
.SiteLogo a:visted{
  color: #3c3c3c;
  text-decoration: none;
}
.SiteLogo a:hover{
  color: #658E9C;
  text-decoration: none;
  
}


/* Footer Styles */

.Footer{
  overflow: hidden;
  position: relative;
  bottom: 0;
  width: 100%;
  margin-top: 50px;
}

.Footer_Content{
  width: 100%;
  padding: 10px 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.Footer_Text{
  font-family: 'Helvetica Neue', 'Times New Roman', Times, sans-serif;
  font-size: 11px;
}
.Footer_Links{

}

.Icon_Style{
  font-size: 18px;
  margin: 0px 15px;
  color: #999999;
}
.Icon_Style a{

}
.Icon_Style a:visited{
  color: #3c3c3c;

}
.Icon_Style a:hover{
  color: #658E9C;
}

/* CSS for Homepage */

.Project_Thumbnail{
  width: 100%;
}
.Project_Thumbnail:hover .Thumbnail_Title a{
  border-bottom: 1px solid #3c3c3c;
}

.Thumbnail_Title{
  font-family: 'Helvetica Neue', 'Times New Roman', Times, sans-serif;
  size: 12px;
  text-align:center;
  text-decoration: none;
}

.Thumbnail_Title a{
  transition: border-bottom 200ms ease;
  box-shadow: none;
  color: #3c3c3c;
}
.Thumbnail_Title a:visited{
  color: #3c3c3c;
  text-decoration: none;
}
.Thumbnail_Title a:hover{
  text-decoration:none;
  color: #658E9C;
  border-bottom: 1px solid #658E9C;
}


/* CSS for About Page*/

 .About{
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.About_Title{
  font-family: 'Padauk', sans-serif;
  font-size: 32px;
  text-align: left;
  margin: 20px;
  color: #ffffff;
  font-weight: 550;
}
.About_Text{
  font-family: 'Roboto', 'Times New Roman', Times, serif;
  font-size: 18px;
  text-align: right;
  margin: 20px;
  color: #000000;
}

.About_Text a{
  color:#000000;
  text-decoration: underline;
}
.About_Text a:hover{
  color:#658E9C;
  
}

.About_Image{
  width: 100%;
}

/* Photo page Styling */

.Caption{
  font-family: 'Helvetica Neue', 'Times New Roman', Times, sans-serif;
  size: 16px;
  text-align: center;
  margin: 10px 20px 30px;
  color: #000000;
}
.Caption a{
  transition: border-bottom 200ms ease;
  color: #938E87;
  text-decoration:none;
}
.Caption a:visited{
  color: #938E87;
  text-decoration: none;
}
.Caption a:hover{
  color:#F54ECD;
  border-bottom: 1px solid #938E87;
}

.Photo_Container{
  margin: 10px 20px 0px;
  padding: 0px;
}
.Photo_Container img{
  padding-bottom: 20px;
}

.Title{
  font-family: "Livory", serif, 'Times New Roman', Times, sans-serif;
  font-size: 25px;
  text-align: center;
  font-weight: 400;
  color: #3c3c3c;
}





